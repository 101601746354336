import React from "react";
import { graphql, Link } from "gatsby";
import { get } from "lodash";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import AlsoRead from "../components/AlsoRead";
import withGraphQLErrors from "../hocs/withGraphQLErrors";
import ContentLayout from "../primitives/ContentLayout";
import SectionLayout from "../primitives/SectionLayout";
import LinkWithArrow from "../primitives/LinkWithArrow";
import LinkButton from "../primitives/LinkButton";
import photoAbout1 from "../images/photo-about-1.jpg";
import photoAbout2 from "../images/photo-about-2.jpg";
import photoAbout3 from "../images/photo-about-3.jpg";
import photoAbout4 from "../images/photo-about-4.jpg";
import etoileDesign from "../images/icon-etoile-design.png";
import umLogo from "../images/logo-um.png";
import enacLogo from "../images/logo-enac.png";
import upvmLogo from "../images/logo-upvm.png";
import sattLogo from "../images/logo-satt.png";
import cnrsLogo from "../images/logo-cnrs.png";
import ensciLogo from "../images/logo-ensci.png";
import dgaLogo from "../images/logo-dga.png";
import airbusLogo from "../images/logo-airbus.png";
import irsteaLogo from "../images/logo-irstea.png";
import foiLogo from "../images/logo-foi.png";
import unimesLogo from "../images/logo-unimes.png";
import dgacLogo from "../images/logo-dgac.png";
import edaLogo from "../images/logo-eda.png";
import aidLogo from "../images/logo-aid.png";
import eurocontrolLogo from "../images/logo-eurocontrol.png";
import sofreaviaLogo from "../images/logo-sofreavia.png";
import styles from "../styles/about.module.scss";

export const query = graphql`
  query AboutPageQuery {
    publications: allSanityPublication(
      limit: 9
      filter: { category: { eq: "publication-scientifique" } }
    ) {
      nodes {
        thumbImage {
          asset {
            _id
          }
          crop {
            bottom
            left
            right
            top
          }
          hotspot {
            height
            x
            width
            y
          }
        }
        subtitle
        title
        category
        backgroundColor {
          value
        }
        _type
        _rawLeadParagraph(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
      }
    }
    page: sanityRoute(slug: { current: { eq: "a-propos" } }) {
      page {
        title
        subtitle
        _rawContent(resolveReferences: { maxDepth: 10 })
        meta {
          metaDescription
          metaTitle
        }
        openGraphImage {
          asset {
            _id
          }
        }
      }
    }
  }
`;

const PARTNERS = [
  ["Université de Montpellier", umLogo],
  ["École Nationale de l’Aviation Civile", enacLogo],
  ["Université Paul Valéry de Montpellier", upvmLogo],
  ["SATT", sattLogo],
  ["CNRS", cnrsLogo],
  ["ENSCI", ensciLogo],
  ["Direction Générale de l'Armement", dgaLogo],
  ["Airbus", airbusLogo],
  ["IRSTEA", irsteaLogo],
  ["FOI", foiLogo],
  ["Université de Nîmes", unimesLogo],
  ["Direction Générale de l’Aviation Civile", dgacLogo],
  ["EDA", edaLogo],
  ["Eurocontrol", eurocontrolLogo],
  ["Sofreavia", sofreaviaLogo],
  ["Agence Innovation Défense", aidLogo],
];

const AboutPage = ({ data }) => {
  const { publications = {}, page = {} } = data || {};

  return (
    <Layout>
      <Seo
        title={
          get(page, "page.meta.metaTitle", "") || get(page, "page.title", "")
        }
        description={get(page, "page.meta.metaDescription", "")}
        image={get(page, "page.openGraphImage", null)}
      />

      <ContentLayout size="large">
        <div className={styles.heroLayout}>
          <div
            className={styles.heroLayoutMedia}
            style={{ backgroundImage: `url(${photoAbout1})` }}
          >
            <blockquote>
              <p>
                “Les entreprises industrielles qui prennent le design au sérieux
                font la différence sur le marché international.”
              </p>
            </blockquote>
            <p>
              <strong>Yves Rinato</strong>, directeur d’intactile DESIGN
            </p>
          </div>
          <div className={styles.heroLayoutBody}>
            <h1 className={styles.pageTitle}>À propos</h1>
            <p>
              En <strong>20 ans d’expérience</strong> et de collaborations dans
              les domaines experts les plus divers, mais aussi avec la
              recherche, notre agence de design a largement démontré ce que son
              fondateur Yves Rinato avait pressenti avant l’heure :{" "}
              <strong>
                dans les domaines pointus, la réalisation d’un bel outil
                numérique ne suffit pas en soi
              </strong>
              .
            </p>
            <p>
              Il faut surtout qu’il fonctionne et soit industrialisable. Il doit
              pour cela non seulement rencontrer un réel plaisir d’usage, mais
              aussi s’intégrer dans une démarche globale d’innovation.
            </p>
            <p>
              L’expertise design d’Intactile, c’est tout cela. Appliquée au
              numérique, elle va bien au-delà de la seule création d’une belle
              interface : elle participe à la conception et à la mise en œuvre
              de stratégies intelligentes d’innovation dans les entreprises,
              pour leur permettre de franchir des paliers de croissance.
            </p>
          </div>
        </div>

        <div className={styles.sideBySideLayout} data-offset-x="positive">
          <div className={styles.paper}>
            <h2 className={styles.sectionTitle}>Conception participative</h2>
            <p>
              Les objets que l’on nous confie sont trop complexes pour sortir de
              la tête d’un designer. Loin de la vision du créateur démiurge,
              sorte de Dieu en train de créer, nous nous positionnons en
              facilitateurs, passeurs, capables de faire accoucher le groupe
              d’une idée. La conception se fait par étapes. À chacune d’entre
              elles, des objets intermédiaires ou “artefacts” (un croquis, un
              scénario, un synopsis, un storyboard, une animation…) amènent à
              élaborer la solution.
            </p>
            <blockquote>
              <p>
                “Je ne connais qu’une agence qui fait ce travail de conception
                participative : intactile DESIGN. Cela se voit dans la diversité
                des objets intermédiaires – ou “artefacts” - que vous apportez
                et qui véhiculent la conception”
              </p>
            </blockquote>
            <p>
              <strong>Anne-Marie BOUTIN</strong>, fondatrice et ancienne
              présidente de l’APCI
            </p>
          </div>
          <div
            className={styles.sideBySideLayoutImage}
            data-offset-y="positive"
          >
            <img src={photoAbout2} alt="" width="325" height="396" />
          </div>
        </div>

        <div className={styles.sideBySideLayout} data-offset-x="negative">
          <div className={styles.sideBySideLayoutImage}>
            <img src={photoAbout3} alt="" width="525" height="615" />
          </div>
          <div className={styles.sideBySideLayoutBody}>
            <h2 className={styles.sectionTitle}>
              L’humain au cœur de l’innovation technologique
            </h2>
            <p>
              Nos sociétés possèdent des cultures technologiques tellement
              poussées qu’elles tendent à s’éloigner de notre nature humaine. Le
              besoin naît alors de les humaniser, de faciliter leur perception,
              leur compréhension, de les rendre aimables et agréables à
              fréquenter.
            </p>
            <p>
              Spécialiste des usages et de la technologie, le designer numérique
              pose l’humain au cœur de l’innovation technologique. Sa démarche
              cherche toujours à identifier les zones de rencontre entre un
              potentiel technologique, qu’il soit simple ou complexe, et un
              plaisir d’usage.
            </p>
            <p>
              Médiateur entre les technologies et les objets que l’on peut
              développer à partir d’elles, il apporte à ces derniers, au-delà de
              l’esthétique, une place dans nos vies, en puisant les essentiels
              qui nous les rendent agréables.
            </p>
          </div>
        </div>

        <div className={styles.sideBySideLayout} data-offset-x="positive">
          <div className={styles.paper} data-size="small">
            <p className={styles.paperStatement}>
              Quand un objet n’est pas désigné, sa forme arrive par accident.
              C’est encore plus vrai pour les objets immatériels dont on imagine
              qu’ils ne coûtent rien et que l’on peut sacrifier le processus de
              conception.
            </p>
            <p className={styles.paperStatement}>
              Si la transformation numérique évoque le gain de productivité, les
              objets numériques sont en réalité infiniment plus complexes que
              les objets physiques.
            </p>
            <LinkWithArrow to="/publications/interview-yves-rinato/">
              Lire l’interview d’Yves Rinato, fondateur d’intactile
            </LinkWithArrow>
          </div>
          <div
            className={styles.sideBySideLayoutImage}
            data-offset-y="positive"
          >
            <img src={photoAbout4} alt="" width="485" height="520" />
          </div>
        </div>
      </ContentLayout>

      <ContentLayout>
        <div className={styles.sideBySideLayout}>
          <div className={styles.sideBySideLayoutImage} data-no-scale>
            <img src={etoileDesign} alt="" width="247" height="165" />
          </div>
          <div className={styles.sideBySideLayoutBody}>
            <h2 className={styles.sectionTitle}>3 étoiles du design</h2>
            <p>
              Notre agence a été 3 fois lauréate du concours organisé par
              l’Observateur du design. En 2006, pour{" "}
              <Link to="/projets/gestion-du-controle-aerien/">
                Mæstro D-MAN
              </Link>{" "}
              de Sofréavia, logiciel de contrôle aérien. En 2012, pour
              l’interface de{" "}
              <Link to="/projets/fibroscan/">FibroScan 502 Touch</Link>{" "}
              d’Echosens, un appareil médical qui mesure l’état de santé du
              foie. En 2018 pour{" "}
              <Link to="/projets/ameliorer-la-lisibilite-et-la-securite-dans-les-cockpits-d-airbus/">
                B612
              </Link>
              , une typographie open source optimisée pour les écrans de cockpit
              des avions, réalisée dans un cadre de recherche avec Airbus et
              l’ENAC.
            </p>
          </div>
        </div>
      </ContentLayout>

      <section>
        <SectionLayout backgroundColor={{ value: "var(--color-bg-dark)" }}>
          <ContentLayout size="small">
            <h1 className={styles.sectionTitleResearch}>
              Intactile DESIGN & la Recherche
            </h1>
            <p>
              Le design est dans la liste des champ d'activité de R&D et
              d’innovation du ministère chargé de la Recherche. Les écoles et
              les universités de design ont ouvert des écoles doctorales
              spécialisées dans cette discipline. Les mentalités ont évoluées et
              l’intérêt de la recherche pour le design a grandi.
            </p>
            <p>
              Notre agence fait de la recherche par le design depuis plus de 10
              ans. Nous intégrons des équipes pluridisciplinaires et nous
              pratiquons notre métier de créatifs avec des chercheurs et des
              sociétés au fort potentiel technologique. Nous explorons
              conjointement des usages, leur esquissons des formes visuelles.
              Nous mettons constamment en œuvre des micro-tests d’appropriation
              des objets numériques inventés, n’hésitant pas à consulter des
              utilisateurs sur des domaines différents.
            </p>
            <LinkButton to="/publications/recherche-par-le-design/">
              Lire la suite
            </LinkButton>
          </ContentLayout>

          <ContentLayout>
            {publications.nodes && publications.nodes.length > 0 && (
              <AlsoRead
                posts={publications.nodes}
                title="Publications scientifiques"
                titleTag="h2"
              />
            )}
            <h2 className={styles.sectionTitlePartners}>
              Nos partenaires de recherche
            </h2>
            <div>
              {PARTNERS.map(([title, image]) => (
                <img
                  src={image}
                  alt={title}
                  key={title}
                  width="120"
                  height="120"
                />
              ))}
            </div>
          </ContentLayout>
        </SectionLayout>
      </section>
    </Layout>
  );
};

export default withGraphQLErrors()(AboutPage);
