import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styles from "./LinkButton.module.scss";

const LinkButton = ({ to, href, children, ...rest }) => {
  if (to) {
    return (
      <Link className={styles.container} to={to}>
        {children}
      </Link>
    );
  }

  const rel = rest.target === "_blank" ? "noopener noreferrer" : "";

  return (
    <a href={href} className={styles.container} rel={rel} {...rest}>
      {children}
    </a>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
};

export default LinkButton;
