import React from "react";
import PropTypes from "prop-types";
import cond from "lodash/cond";
import constant from "lodash/constant";
import ProjectTile from "./ProjectTile";
import styles from "./AlsoRead.module.scss";

const isOfType = ofType => type => type === ofType;

const postUrlPrefix = cond([
  [isOfType("publication"), constant("publications")],
  [isOfType("project"), constant("projets")],
]);

const AlsoRead = ({ posts, title, titleTag }) => {
  return (
    <section className={styles.container}>
      {React.createElement(titleTag, { className: styles.title }, title)}
      <div className={styles.posts}>
        {posts.map(
          ({
            slug,
            title,
            subtitle,
            _rawLeadParagraph,
            _type,
            thumbImage,
            backgroundColor,
          }) => {
            return (
              <ProjectTile
                key={slug.current}
                slug={`/${postUrlPrefix(_type)}/${slug.current}/`}
                title={title}
                subtitle={subtitle}
                leadParagraph={_rawLeadParagraph}
                thumbnail={thumbImage}
                backgroundColor={backgroundColor}
              />
            );
          }
        )}
      </div>
    </section>
  );
};

AlsoRead.defaultProps = {
  title: "À lire aussi",
  titleTag: "h1",
};

AlsoRead.propTypes = {
  posts: PropTypes.array.isRequired,
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(["h1", "h2", "h3"]),
};

export default AlsoRead;
