import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styles from "./LinkWithArrow.module.scss";

const LinkWithArrow = ({ to, children }) => {
  return (
    <Link className={styles.container} to={to}>
      <span>{children}</span>
      <i className={styles.arrow} aria-hidden="true">
        <svg viewBox="0 0 21 16" xmlns="http://www.w3.org/2000/svg">
          <line y1="8" x2="20" y2="8" />
          <path
            d="M13.5 1.5L20 8L13.5 14.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </i>
    </Link>
  );
};

LinkWithArrow.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default LinkWithArrow;
